export class Colors {
  primary: string = '#039DE2';
  primary_dark: string = '#039DE2';
  secondary: string = '#77B826';
  danger: string = '#f53d3d';
  warning: string = '#FFCC00';
  light: string = '#EFEFEF';
  gray: string = '#989aa2';
  dark: string = '#0C3235';
  black: string = '#000000';
  white: string = '#ffffff';
  primary_contrast: string = '#ffffff';
  toolbar = '#F8F8F8';
}
export class AppConfig {
  colors: Colors = new Colors();
  title: string = 'Elywhere';
  appID: string = '';
  appHost: string = 'elywhereapp.smartcharge.io';
  showTitle: boolean = false;
  defaultLang: string = 'en';
  appToken: string = 'ad783bd2-58b4-41e5-9767-17187009c3f2';
  pushAppID: string = 'a54f16a4-4f77-4a8b-b273-450e7de206ad';
  pushSenderID: string = '694772183137';
  root: string = 'https://elywhereapp.smartcharge.io';
  readonly isWhitelabel: boolean = this.appToken ? true : false;
  stationIcon: StationIcon = { height: 44, width: 30 };
  availableLangs: string[] = ['nb', 'en', 'da', 'sv'];
  toolbarLogoHeight: string = '1.9em';
  privacyPolicy: any = {
    nb: 'https://www.elywhere.com/privacy-policy',
    en: 'https://www.elywhere.com/privacy-policy'
  };
  termsAndConditions: any = {
    nb: 'https://www.elywhere.com/terms-and-conditions',
    en: 'https://www.elywhere.com/terms-and-conditions'
  };
  contact: any = {
    external: false,
    email: 'support@elywhere.no',
    phone: '+47 900 58020',
    web: '',
    chat: '',
    customerService: {
      nb: 'https://www.elywhere.com/contact',
      en: 'https://www.elywhere.com/contact'
    }
  };
  constructor() {}
}

interface StationIcon {
  height: number;
  width: number;
}
